import { Typography } from '@mui/material';
import React from 'react';

import './Scene.scss';
import { useNavigate } from 'react-router-dom';

const Scene = (props) => {
  const navigate = useNavigate();

  const handleClickOnScene = () => {
    navigate(`/scenes/${props.scene.id}`);
  }

  return (
    <div className='Scene' onClick={handleClickOnScene}>
      <img className='Scene__Thumbnail' src={props.scene.image} alt={props.scene.name} />
      <Typography variant='h6'>{props.scene.name}</Typography>
      <Typography variant='body1'>{props.scene.description}</Typography>

    </div>
  );
}

export default Scene;