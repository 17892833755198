import { useTheme } from "@emotion/react";
import React from "react";

import "./Logo.scss";

const Logo = () => {
  const theme = useTheme();

  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="30px"
      height="30px"
      viewBox="0 0 400 400"
      className="Logo"
    >
     
      <g>
        <path
          d="M291.77,346.33c-9.22,0-18.31-4.23-24.19-12.23l-75.83-103.12L115.92,334.1c-9.81,13.35-28.59,16.21-41.94,6.4
		c-13.35-9.82-16.21-28.59-6.4-41.94l100-135.99c5.65-7.69,14.63-12.23,24.17-12.23c9.54,0,18.52,4.54,24.17,12.23l100,135.99
		c9.82,13.35,6.95,32.13-6.4,41.94C304.17,344.43,297.94,346.33,291.77,346.33z"
    />
      </g>
      <g>
        <path d="M291.75,104.33h-200c-16.57,0-30-13.43-30-30s13.43-30,30-30h200c16.57,0,30,13.43,30,30S308.31,104.33,291.75,104.33z"/>
      </g>
    </svg>
  );
};

export default Logo;
