import React from 'react';

import './Scenes.scss';
import scenes from '../../data/scenes.json';
import { Paper, Typography } from '@mui/material';
import Scene from '../../components/Scene/Scene';
import { useNavigate } from 'react-router-dom';

const Scenes = () => {
  return (<Paper className='Scenes' elevation={0}>
    <Typography variant='h4'>Scenes</Typography>
    <div className='Scenes__Gallery'>
      {scenes.map((scene, index) => 
        <Scene key={index} scene={scene} />
      )}
    </div>
  </Paper>) 
}

export default Scenes;