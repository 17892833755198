import {
  IconButton,
  Tooltip,
} from "@mui/material";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import React from "react";
import { useNavigate } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import "./MainMenu.scss";
import Logo from "../../components/Logo/Logo";

const MainMenu = (props) => {
  const navigate = useNavigate();

  const handleClickOnMain = () => {
    navigate("/");
  };

  const handleClickOnScenes = () => {
    navigate("/scenes");
  };

  const handleClickOnAbout = () => {
    navigate("/about");
  };

  return (
    <div className="MainMenu">
      <Tooltip title="Apptly" placement="right">
        <IconButton onClick={handleClickOnMain}>
          <Logo />
        </IconButton>
      </Tooltip>
      <div className="MainMenu__Separator"></div>
      <Tooltip title="Gallery" placement="right">
        <IconButton onClick={handleClickOnScenes}>
          <BackupTableIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="About" placement="right">
        <IconButton onClick={handleClickOnAbout}>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default MainMenu;
