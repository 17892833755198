//create mui theme
import { createTheme } from  '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#5100ab',
    },
    secondary: {
      main: '#990dc5',
    },
    background: {
      default: '#fff',
    },
  },
  shadows: ['none'],
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 30,
          elevation: 0,
          shadow: 0,
          width: 'fit-content',
          textTransform: 'none'
        }
      }
    },
  },
});
export default theme;