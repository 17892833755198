import { Button, Typography } from "@mui/material";
import React from "react";

import "./About.scss";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const handleClickOnGallery = () => {
    navigate("/scenes");
  };

  return (
    <div className="About">
       <Typography variant="h4">
        About Apptly
      </Typography>
      <Typography variant="body1">
        As a prototype, the platform may exhibit occasional
        technical issues or unexpected behavior. Although we are continuously
        working to enhance Apptly's functionality and user experience, we cannot
        guarantee its performance or the accuracy of its results at all times.
        Users should exercise caution when using Apptly for important projects
        and consider verifying the accuracy of generated mockups independently.
        We appreciate your understanding and support as we continue to improve
        and refine Apptly.
      </Typography>
      <Typography variant="body1">
        Apptly is currently in its prototype stage and is being developed by 
        <a href="https://xavicolomer.com" target="_blank">Xavier Colomer</a>.
      </Typography>
    </div>
  );
};

export default About;
