import React, { lazy, useEffect, useRef } from "react";
import "./Viewer.scss";
import { useParams } from "react-router-dom";
import scenes from "../../data/scenes.json";

const Viewer2D = lazy(() => import('./2DViewer'));
const Viewer3D = lazy(() => import('./3DViewer'));

const Viewer = (props) => {
  const [scene, setScene] = React.useState({});
  const params = useParams();
  
  useEffect(() => {
    if (params && params.sceneId) {
      const scene = scenes.find(
        (scene) => scene.id.toString() === params.sceneId.toString()
      );

      setScene(scene);
    }
  }, [params.sceneId]);


  if (!scene) {
    return null;
  }

  return (
    <div>
      {scene && scene.type === "2d" && <Viewer2D scene={scene} />}
      {scene && scene.type === "3d" && <Viewer3D scene={scene} />}
    </div>
  );
};

export default Viewer;
