//create basic APP

import React, { Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme/theme";
import MainMenu from "../MainMenu/MainMenu";

import "./App.scss";
import Scenes from "../Scenes/Scenes";
import Main from "../Main/Main";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Viewer from "../Viewer/Viewer";
import About from "../About/About";

const router = createBrowserRouter([
  {
    path: "/scenes",
    element: (
      <div className="App">
        <MainMenu />
        <Scenes />
      </div>
    ),
  },
  {
    path: "/about",
    element: (
      <div className="App">
        <MainMenu />
        <About />
      </div>
    ),
  },
  {
    path: "/scenes/:sceneId",
    element: (
      <div className="App">
        <MainMenu />
        <Viewer />
      </div>
    ),
  },
  {
    path: "/",
    element: (
      <div className="App">
        <MainMenu />
        <Main />
      </div>
    ),
  },
]);

const App = () => (
  <Suspense fallback={<div />}>
  <ThemeProvider theme={theme}>
    <RouterProvider router={router}></RouterProvider>
  </ThemeProvider>
  </Suspense>
);

export default App;
