import { Button, Typography } from "@mui/material";
import React from "react";

import "./Main.scss";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();

  const handleClickOnGallery = () => {
    navigate("/scenes");
  }
  
  return (
    <div className="Main">
      <Typography variant="h1" className="Main__Title">
        Welcome to Apptly
      </Typography>
      <Typography variant="body1">
        Introducing Apptly, your one-stop solution for effortlessly designing
        and creating stunning mockups in just a few clicks. Say goodbye to the
        tedious process of manually designing mockups for PowerPoint
        presentations, videos, and other digital media. 
      </Typography>
      <Button variant="contained" size="large" className="Main__Button" onClick={handleClickOnGallery}>
        Get Started
      </Button>
    </div>
  );
};

export default Main;
